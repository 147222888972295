<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <!--    Editor Modal  -->
    <b-modal
        id="modal-Editor"
        centered
        ok-only
        ok-title="ثبت"
        title="افزودن کد html"
        @ok.preventDefault()="customButtonClick"
    >
      <b-row>
        <span class="mx-1 mb-1">کد HTML خود را در اینجا قرار دهید</span>
        <b-col cols="12">
          <textarea v-model="rawHTML" class="form-control"></textarea>
        </b-col>
      </b-row>
    </b-modal>

    <div>
      <b-row>
        <b-col cols="12">
          <b-card>

            <h4>اطلاعات کلی</h4>

            <!-- Media -->
            <b-media class="my-2">
              <template >
                <b-avatar
                    ref="previewEl"
                    :src="base64ImageSrc"
                    size="300px"
                    rounded
                />
              </template>
              <div class="d-flex flex-wrap mt-1">
                <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                >
                  <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="makeBase64Pic"
                  >
                  <span class="d-none d-sm-inline">+ عکس</span>
                  <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
                <b-button
                    variant="outline-secondary"
                    class="ml-1"
                    @click="deleteProductPic()"
                >
                  <span class="d-none d-sm-inline">حذف</span>
                  <feather-icon
                      icon="TrashIcon"
                      class="d-inline d-sm-none"
                  />
                </b-button>
              </div>
            </b-media>

            <div>
              <b-form>
                <b-row>

                  <!-- Field:  Title -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="عنوان"
                        label-for="Title"
                    >
                      <b-form-input
                          id="Title"
                          v-model="defaultData.title"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: isFree -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="وضعیت"
                        label-for="user-status"
                    >
                      <v-select
                          v-model="defaultData.isFree"
                          :options="isFreeOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="user-status"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field:  price -->
                  <b-col
                      v-if="!defaultData.isFree"
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="قیمت (تومان)"
                        label-for="price"
                    >
                      <b-form-input
                          id="price"
                          v-model="defaultData.price"
                          :aria-disabled="!defaultData.isFree"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: isAvailable -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="موجودیت"
                        label-for="isAvailable"
                    >
                      <v-select
                          v-model="defaultData.available"
                          :options="isAvailableOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          input-id="isAvailable"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field:  subtitle -->
                  <b-col
                      cols="12"
                  >
                    <b-form-group
                        label="سوتیتر"
                        label-for="subtitle"
                    >
                      <b-form-input
                          id="subtitle"
                          v-model="defaultData.subtitle"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field:  description -->
                  <b-col cols="12">
                    <b-form-group
                        class="mb-2"
                        label="توضیحات"
                        label-for="product-content"
                    >
                      <quill-editor
                          v-model="defaultData.description"
                          :options="snowOption"
                      >
                        <div
                            id="toolbar"
                            slot="toolbar"
                        >
                          <!-- You can also add your own -->
                          <span class="ql-format">

                      <button
                          class="btn btn-primary"
                          style="width:auto"
                          type="button"
                          @click="customButtonClick"
                      >
                        < html />
                      </button>
                      </span>
                          <span class="ql-formats">
                        <button class="ql-header" type="button" value="1">
                          h1
                        </button>
                        <button class="ql-header" type="button" value="2">
                          h2
                        </button>
                        <button class="ql-header" type="button" value="3">
                          h3
                        </button>
                        <button class="ql-header" type="button" value="4">
                          h4
                        </button>
                        <button class="ql-header" type="button" value="5">
                          h5
                        </button>
                        <button class="ql-header" type="button" value="6">
                          h6
                        </button>
                      </span>
                          <span class="ql-formats">
                        <button class="ql-script" type="button" value="sub">
                          <svg viewBox="0 0 18 18">
                            <path class="ql-fill"
                                  d="M15.5,15H13.861a3.858,3.858,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.921,1.921,0,0,0,12.021,11.7a0.50013,0.50013,0,1,0,.957.291h0a0.914,0.914,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.076-1.16971,1.86982-1.93971,2.43082A1.45639,1.45639,0,0,0,12,15.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,15Z"></path> <path
                              class="ql-fill"
                              d="M9.65,5.241a1,1,0,0,0-1.409.108L6,7.964,3.759,5.349A1,1,0,0,0,2.192,6.59178Q2.21541,6.6213,2.241,6.649L4.684,9.5,2.241,12.35A1,1,0,0,0,3.71,13.70722q0.02557-.02768.049-0.05722L6,11.036,8.241,13.65a1,1,0,1,0,1.567-1.24277Q9.78459,12.3777,9.759,12.35L7.316,9.5,9.759,6.651A1,1,0,0,0,9.65,5.241Z"></path> </svg>
                        </button>
                        <button class="ql-script" type="button" value="super">
                          <svg viewBox="0 0 18 18"> <path class="ql-fill"
                                                          d="M15.5,7H13.861a4.015,4.015,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.922,1.922,0,0,0,12.021,3.7a0.5,0.5,0,1,0,.957.291,0.917,0.917,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.077-1.164,1.925-1.934,2.486A1.423,1.423,0,0,0,12,7.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,7Z"></path> <path
                              class="ql-fill"
                              d="M9.651,5.241a1,1,0,0,0-1.41.108L6,7.964,3.759,5.349a1,1,0,1,0-1.519,1.3L4.683,9.5,2.241,12.35a1,1,0,1,0,1.519,1.3L6,11.036,8.241,13.65a1,1,0,0,0,1.519-1.3L7.317,9.5,9.759,6.651A1,1,0,0,0,9.651,5.241Z"></path> </svg>
                        </button>
                      </span>
                          <span class="ql-formats"><button class="ql-indent" type="button" value="-1"><svg
                              viewBox="0 0 18 18"> <line class="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line
                              class="ql-stroke" x1="3" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="9"
                                                                                           x2="15"
                                                                                           y1="9" y2="9"></line> <polyline
                              class="ql-stroke" points="5 7 5 11 3 9 5 7"></polyline> </svg>
                      </button>
                        <button class="ql-indent" type="button" value="+1"><svg viewBox="0 0 18 18"> <line
                            class="ql-stroke" x1="3" x2="15" y1="14" y2="14"></line> <line class="ql-stroke" x1="3"
                                                                                           x2="15" y1="4" y2="4"></line> <line
                            class="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line> <polyline
                            class="ql-fill ql-stroke" points="3 7 3 11 5 9 3 7"></polyline> </svg>
                        </button>
                      </span>
                          <span class="ql-formats">
                        <button class="ql-link" type="button"><svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="7"
                                                                                              x2="11" y1="7"
                                                                                              y2="11"></line> <path
                            class="ql-even ql-stroke"
                            d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path> <path
                            class="ql-even ql-stroke"
                            d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path> </svg>
                        </button>
                        <button class="ql-image" type="button"><svg viewBox="0 0 18 18"> <rect class="ql-stroke"
                                                                                               height="10" width="12"
                                                                                               x="3" y="4"></rect> <circle
                            class="ql-fill" cx="6" cy="7" r="1"></circle> <polyline class="ql-even ql-fill"
                                                                                    points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"></polyline> </svg>
                        </button>
                        <button class="ql-video" type="button"><svg viewBox="0 0 18 18"> <rect class="ql-stroke"
                                                                                               height="12" width="12"
                                                                                               x="3" y="3"></rect> <rect
                            class="ql-fill" height="12" width="1" x="5" y="3"></rect> <rect class="ql-fill" height="12"
                                                                                            width="1" x="12"
                                                                                            y="3"></rect> <rect
                            class="ql-fill" height="2" width="8" x="5" y="8"></rect> <rect class="ql-fill" height="1"
                                                                                           width="3" x="3" y="5"></rect> <rect
                            class="ql-fill" height="1" width="3" x="3" y="7"></rect> <rect class="ql-fill" height="1"
                                                                                           width="3" x="3"
                                                                                           y="10"></rect> <rect
                            class="ql-fill" height="1" width="3" x="3" y="12"></rect> <rect class="ql-fill" height="1"
                                                                                            width="3" x="12"
                                                                                            y="5"></rect> <rect
                            class="ql-fill" height="1" width="3" x="12" y="7"></rect> <rect class="ql-fill" height="1"
                                                                                            width="3" x="12"
                                                                                            y="10"></rect> <rect
                            class="ql-fill" height="1" width="3" x="12" y="12"></rect> </svg>
                        </button>
                      </span>

                          <span class="ql-formats">
                        <button class="ql-list" type="button" value="ordered"><svg viewBox="0 0 18 18"> <line
                            class="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="7"
                                                                                         x2="15" y1="9" y2="9"></line> <line
                            class="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line
                            class="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path class="ql-fill"
                                                                                                        d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path
                            class="ql-stroke ql-thin"
                            d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path
                            class="ql-stroke ql-thin"
                            d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg>
                        </button>
                        <button class="ql-list" type="button" value="bullet"><svg viewBox="0 0 18 18"> <line
                            class="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line
                            class="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="6"
                                                                                         x2="15"
                                                                                         y1="14" y2="14"></line> <line
                            class="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line class="ql-stroke" x1="3" x2="3"
                                                                                        y1="9" y2="9"></line> <line
                            class="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg>
                        </button>
                      </span>
                          <!-- Add a bold button -->
                          <span class="ql-formats">
                        <button class="ql-bold" type="button">
                            <feather-icon icon="BoldIcon"></feather-icon>
                        </button>
                        <button class="ql-italic" type="button">
                            <feather-icon icon="ItalicIcon"></feather-icon>
                        </button>
                        <button
                            class="ql-underline" type="button">
                          <feather-icon icon="UnderlineIcon"></feather-icon>
                        </button>
                        <button
                            class="ql-strike" type="button">
                          <svg viewBox="0 0 18 18"> <line class="ql-stroke ql-thin" x1="15.5" x2="2.5" y1="8.5"
                                                          y2="9.5"></line> <path class="ql-fill"
                                                                                 d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"></path> <path
                              class="ql-fill"
                              d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"></path> </svg>
                        </button>
                      </span>
                          <!-- Add font size dropdown -->
                          <select class="ql-size">
                            <option value="small"/>
                            <!-- Note a missing, thus falsy value, is used to reset to default -->
                            <option selected/>
                            <option value="large"/>
                            <option value="huge"/>
                          </select>

                          <select class="ql-font">
                            <option selected="selected"/>
                            <option value="serif"/>
                            <option value="monospace"/>
                          </select>

                          <!-- Add subscript and superscript buttons -->
                          <button
                              class="ql-script ql"
                              value="sub"
                          />
                          <button
                              class="ql-script"
                              value="super"
                          />

                        </div>

                      </quill-editor>
                    </b-form-group>
                    <textarea v-if="showHTML" dir="ltr" id="product-content" v-model="rawHTML" class="form-control mb-2 " cols="30"
                              name="textArea"
                              rows="10"></textarea>
                  </b-col>

                  <!-- Field: productCategoryId -->
                  <b-col v-if="productCategories !== null && defaultData.type !== 0 && defaultData.type !== 1 && defaultData.type !== 10"
                         cols="12"
                         md="4"
                  >
                    <b-form-group
                        label="دسته بندی"
                        label-for="productCategoryId"
                    >
                      <v-select
                          v-model="defaultData.productCategoryId"
                          :options="productCategories"
                          label="name"
                          :reduce="name => name.productCategoryId"
                          :clearable="false"
                          input-id="productCategoryId"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: productCategoryId -->
                  <b-col v-if="courseCategories !== null && defaultData.type !== 0 && defaultData.type === 1 "
                         cols="12"
                         md="4"
                  >
                    <b-form-group
                        label="دسته بندی"
                        label-for="productCategoryId"
                    >
                      <v-select
                          v-model="defaultData.productCategoryId"
                          :options="courseCategories"
                          label="name"
                          :reduce="name => name.productCategoryId"
                          :clearable="false"
                          input-id="productCategoryId"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: courseId -->
                  <b-col
                      cols="12"
                      md="4"
                  >
                    <b-form-group
                        label="انتخاب دوره"
                        label-for="type"
                    >
                      <v-select
                          v-if="courses"
                          v-model="defaultData.courseId"
                          :options="courses"
                          label="name"
                          :reduce="name => name.courseId"
                          :clearable="true"
                          input-id="productCategoryId"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-form>

              <b-button
                  variant="primary"
                  class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="createProduct"
              >
                ثبت
              </b-button>

            </div>
          </b-card>

          <!--  Category Properties  -->
          <b-card ref="categoryProperties" v-if="defaultData.productCategoryId !== null">

            <h4 class="text-primary">خصوصیات پیشفرض</h4>

            <hr class="my-2">

            <b-row>
              <b-col
                  v-for="i in defaultData.properties"
                  v-if="i.categoryPropertyId > 0"
                  cols="8"
              >
                <b-form-group
                    label-cols="4"
                    label-size="lg"
                    :label="i.name"
                    :label-for="i.name"
                >
                  <b-form-input
                      :id="i.name"
                      v-model="i.value"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>

              <b-col cols="12">
                <h4 class="text-primary">خصوصیات اضافی</h4>

                <hr class="my-2">
              </b-col>

              <NewCategoryProperty
                  v-for="i in defaultData.properties"
                  :key="i.id"
                  :propertyData="i"
              />

              <!--            <b-col-->
              <!--                v-for="(i,index) in defaultData.properties"-->
              <!--                v-if="i.name === ''"-->
              <!--                cols="12"-->
              <!--            >-->
              <!--              <b-row>-->

              <!--                <b-form-group-->
              <!--                    class="col-3"-->
              <!--                    label="name"-->
              <!--                    label-for=""-->
              <!--                >-->
              <!--                  <b-form-input-->

              <!--                      v-model="i.name"-->
              <!--                  />-->
              <!--                </b-form-group>-->

              <!--                <b-form-group-->
              <!--                    class="col-5"-->
              <!--                    label="value"-->
              <!--                    label-for=""-->
              <!--                >-->
              <!--                  <b-form-input-->

              <!--                      v-model="i.value"-->
              <!--                  />-->
              <!--                </b-form-group>-->

              <!--              </b-row>-->
              <!--            </b-col>-->
            </b-row>

            <b-button
                variant="primary"
                @click="defaultData.properties.push({'name':'','value':'','categoryPropertyId':0})"
            >
              <span class="text-nowrap">+ افزودن خصوصیت</span>
            </b-button>

          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay,
  BModal
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ProductCategoryGetAllRequest} from "@/libs/Api/Product";
import {ProductCategoryPropertyGetByCategoryIdRequest} from "@/libs/Api/Product";
import {ProductCreateRequest} from "@/libs/Api/Product";
import {ProductCourseGetAllRequest} from "@/libs/Api/Product";
import NewCategoryProperty from "@/views/apps/e-commerce/e-commerce-add/NewCategoryProperty";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductCategoryGetAllProductCategoriesRequest} from "@/libs/Api/Product";
import {ProductCategoryGetAllCourseCategoriesRequest} from "@/libs/Api/Product";

// Quill
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {quillEditor} from "vue-quill-editor";


export default {
  title:"افزودن محصول به پکیج - پنل ادمین مکس ",
  name: "ECommerceAdd",
  components:{
    NewCategoryProperty,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BModal
  },
  data(){
    return{
      overlay:false,
      propertiesData:[],
      defaultData:{
        isDeleted: false,
        productId: 0,
        parentId:this.$route.params.id,
        title: "",
        description: "",
        productCategoryId: null,
        courseScheduleId: 0,
        courseId: null,
        price: "",
        isFree: false,
        type: 6,
        imageFile: "",
        fileData: null,
        subtitle: '',
        trailerFile:'',
        viewCounts: 0,
        properties: [],
        available:true,
      },
      showHTML: false,
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar'
        },
      },
      rawHTML: '',
      isFreeOptions:[
        {label : 'رایگان' , value : true},
        {label : 'پولی' , value : false}
      ],
      types:[
        {label : 'دوره' , value : 1},
        {label : 'فایل' , value : 3},
        {label : 'پادکست' , value : 4},
        {label: 'پکیج', value: 6},
      ],
      isAvailableOptions:[
        {label : 'موجود' , value : true},
        {label : 'ناموجود' , value : false}
      ],
      base64ImageSrc:null,
      productCategories:null,
      courseCategories:null,
      categoryProperties:null,
      courses:null,
    }
  },
  computed:{
    defaultDataProductCategoryId(){
      return this.defaultData.productCategoryId;
    },
    defaultDataProductType(){
      return this.defaultData.type;
    },
    defaultDataImageFile(){
      return this.defaultData.imageFile
    }
  },
  watch:{
    defaultDataProductCategoryId(nv,ov){
      this.getCategoryProperties(nv)
    },
    defaultDataProductType(nv,ov){

    },
  },
  async created(){
    await this.getAllProductCategories();
    await this.getAllCourseCategories();
    await this.getAllCourses();
  },
  methods:{
    async getAllProductCategories(){
      let  _this = this;
      let  defaultGetData={
        pageNumber:0,
        count:0,
      }

      let productCategoryGetAllProductCategoriesRequest = new ProductCategoryGetAllProductCategoriesRequest(_this);
      productCategoryGetAllProductCategoriesRequest.setParams(defaultGetData);
      await  productCategoryGetAllProductCategoriesRequest.fetch(function (content){
        _this.productCategories = content.productCategories;
      },function (error){
        console.log(error)
      })
    },
    async getAllCourseCategories(){
      let  _this = this;
      let  defaultGetData={
        pageNumber:0,
        count:0,
      }

      let productCategoryGetAllProductCategoriesRequest = new ProductCategoryGetAllCourseCategoriesRequest(_this);
      productCategoryGetAllProductCategoriesRequest.setParams(defaultGetData);
      await  productCategoryGetAllProductCategoriesRequest.fetch(function (content){
        _this.courseCategories = content.productCategories;
      },function (error){
        console.log(error)
      })
    },
    async createProduct(){
      let  _this = this;
      _this.overlay = true;

      let productCreateRequest = new ProductCreateRequest(_this);
      productCreateRequest.setParams(_this.defaultData);
      await productCreateRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.$router.push({ name: 'apps-e-commerce-packages-list-id', params: { id: _this.productId }})
      }, function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async getCategoryProperties(param){
      let _this = this;

      let productCategoryPropertyGetByCategoryIdRequest = new ProductCategoryPropertyGetByCategoryIdRequest(_this);
      productCategoryPropertyGetByCategoryIdRequest.setId(param);
      await productCategoryPropertyGetByCategoryIdRequest.fetch(function (content){
        _this.categoryProperties = content
        _this.defaultData.properties = [];
        _this.categoryProperties.forEach(element => _this.defaultData.properties.push({'name':element.name,'val':'','categoryPropertyId':element.categoryPropertyId}))
        _this.$refs.categoryProperties.scrollIntoView({ behavior: "smooth" });
      },function (error){
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async getAllCourses(){
      let _this = this;

      let courseData = {
        pageNumber:0,
        searchCommand : '',
        count: 0
      }

      let productCourseGetAllRequest = new ProductCourseGetAllRequest(_this);
      productCourseGetAllRequest.setParams(courseData);
      await productCourseGetAllRequest.fetch(function (content){
        _this.courses = content.result.data.courses;
      },function (error){
        console.log(error);
      })
    },
    async makeBase64Pic(e){
      const _this = this;
      let file = e.target.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      const result = await _this.readAsDataURL(file);
      _this.defaultData.imageFile = result;
    },
    async makeBase64(e){
      const _this = this;
      let file = e.target.files[0]
      const result = await _this.readAsDataURL(file);
      _this.defaultData.trailerFile = result;
    },
    deleteProductPic(){
      this.defaultData.imageFile = '';
      this.base64ImageSrc = null
    },
    customButtonClick() {
      this.showHTML = !this.showHTML

      const editor = document.querySelector('.ql-editor')
      if (editor.classList.contains('d-none')) {
        this.product.description = this.rawHTML
        editor.classList.remove('d-none')

      } else {
        this.rawHTML = this.product.description
        editor.classList.add('d-none')
      }
    },
  },
}
</script>

<style scoped>

</style>